
import { defineComponent, reactive, ref, toRefs, useContext, inject, watchEffect, useStore, useRoute } from '@nuxtjs/composition-api'
import { useLink } from '@/hooks/useLink'
import { integerReg } from '@/utils/validate'
import { useMsgDialog } from '@/hooks/useMsgDialog'
import { mathDiv, mathMul } from '@/utils/tool'
export default defineComponent({
  props: {
    addCartRow: {
      type: Object,
      default: () => {}
    },
    zone: {
      type: String
    }
  },
  setup(props, { emit }) {
    const context = useContext()
    const route = useRoute()
    const store = useStore()
    const $message = inject('$message')
    const { showErrorTip } = useMsgDialog()
    const dialogVisible = ref(false)
    const commodity = ref({
      goodsUrl: '',
      quantity: 0,
      categoryName: '',
      material: '',
      spec: '',
      origin: ''
    })
    watchEffect(() => {
      Object.assign(commodity.value, { ...props.addCartRow })
    })
    const state = reactive({
      hasChoseNum: 0,
      allWeight: 0,
      allMoney: 0,
      choseNum: 1,
      tempChoseNum: 1
    })
    const sureAddCart = async () => {
      const { statusCode, data, message } = await context.$api.orderCart.addCart({
        resourceId: props.addCartRow.resourceId,
        quantity: state.choseNum,
        zone: props.zone
      })
      if (statusCode == 200) {
        $message.success('加入购物车成功')
        dialogVisible.value = false
        store.dispatch('spotMall/GET_CARTSNUMBER', context)
        emit('success', {
          quantity: state.choseNum
        })
      } else if (statusCode == 30010 || statusCode == 30020) {
        showErrorTip(message)
      } else {
        $message.error(message)
      }
    }
    const { push } = useLink()
    const sureBuy = async () => {
      const { data, statusCode, message } = await context.$api.orderCart.validateBuyNow({
        type: 1,
        quantity: state.choseNum,
        resourceIds: [commodity.value.resourceId]
      })
      if (statusCode == 200) {
        push('/shop/carts', { type: 1, quantity: state.choseNum, resourceIds: commodity.value.resourceId, redirect: route.value.fullPath })
      } else if (statusCode == 30010 || statusCode == 30020) {
        showErrorTip(message)
      } else if (statusCode == 50030) {
        showErrorTip(message, 9)
      } else {
        $message.error(message)
      }
    }
    const changeInputNumber = (e) => {
      if (e && integerReg.test(e)) {
        state.tempChoseNum = state.choseNum
      }
      state.hasChoseNum = state.choseNum
      state.allWeight = MathRound(mathMul(state.choseNum, mathDiv(commodity.value.weight, commodity.value.quantity)), 3)
      state.allMoney = MathRound(mathMul(state.allWeight, commodity.value.listedPrice || 0), 2)
    }
    const openDialog = () => {
      state.choseNum = 1
      changeInputNumber()
    }
    const showInfo = (row) => {
      // window.open(`/shop/${row.resourceId}`, '_blank')
      window.open(`/shop/goods?id=${encodeURI(row.resourceId)}`, '_blank')
    }
    const blurNumber = (e) => {
      if (!e.target.value || !integerReg.test(e.target.value)) {
        state.choseNum = state.tempChoseNum
      }
      changeInputNumber()
    }

    const MathRound = (number, pow = 2) => {
      return Math.round(number * Math.pow(10, pow)) / Math.pow(10, pow)
    }
    return {
      dialogVisible,
      sureAddCart,
      sureBuy,
      changeInputNumber,
      ...toRefs(state),
      commodity,
      openDialog,
      showInfo,
      blurNumber
    }
  }
})
