
import { defineComponent, ref, useContext } from '@nuxtjs/composition-api'
import warrantyTag from '@/components/spotMall/warrantyTag.vue'
import attributeTable from '@/components/spotMall/attributeTable.vue'
import { formatNumber } from '@/utils/tool'
import { useLoginInfo } from '@/hooks/useLoginInfo'
import { invoiceModeSecond } from '@/constant/goods'
import { useMsgDialog } from '@/hooks/useMsgDialog'
import { C2M_REPO_IDS, JIN_MAO_ID, CLASS_C_IDS } from '@/constant'

export default defineComponent({
  components: {
    attributeTable,
    warrantyTag
  },
  props: {
    goodsList: {
      type: Array,
      default: () => []
    },
    isBatch: {
      type: Boolean,
      default: false
    },
    checked: {
      type: Boolean,
      default: false
    },
    zone: {
      type: String
    }
  },
  setup(props, { emit }) {
    const context = useContext()
    const { $config } = context
    const { userInfo, loginCorpId, isJinMaoCompany } = useLoginInfo()
    const { judgeIsLogin } = useMsgDialog()
    const columns = ref([
      {
        width: props.isBatch ? 35 : 1,
        render: (row) => {
          return (
            <el-checkbox
              style={!props.isBatch ? 'display:none' : ''}
              value={row.checked}
              disabled={!getGoodsAddState(row)}
              onChange={() => handleChange(row)}
            ></el-checkbox>
          )
        }
      },
      {
        width: 250,
        align: 'left',
        render: (row) => {
          return (
            <div>
              <div>{`商品编号：${row.resourceId ?? '-'}`}</div>
              <div>{`${CLASS_C_IDS.includes(row.repositoryId) ? '入库日期' : '挂网日期'}：${row.warehouseDate?.replace(/\//g, '-') ?? '-'}`}</div>
            </div>
          )
        }
      },
      {
        align: 'left',
        width: 230,
        render: (row) => {
          return (
            <div>
              <div>{`库位信息：${getLocation(row)}`}</div>
              <div>{`出库费：${row.outboundCost ?? 0}元/吨`}</div>
            </div>
          )
        }
      },
      {
        align: 'left',
        width: 180,
        render: (row) => {
          return (
            <div>
              <div>{`${formatNumber(row.quantity)}件/${formatNumber(row.weight, 3)}吨`}</div>
              <div>{row.measure ?? '-'}</div>
            </div>
          )
        }
      }
    ])

    const getLocation = (row) => {
      if (row.gylWzstr03 == null) {
        return '-'
      } else {
        return `${row.gylWzstr03 ?? ''} | ${+row.gylWzstr04 ?? ''} | ${+row.gylWzstr05 ?? ''}`
      }
    }

    const handleChange = (row) => {
      row.checked = !row.checked
      emit('change')
    }

    const handleDetail = (row) => {
      window.open(`/shop/goods?id=${encodeURI(row.resourceId)}&zone=${props.zone}`, '_blank')
    }

    const handleAddCart = (row) => {
      emit('addCart', row)
    }

    const handleQuality = (data) => {
      emit('quality', data)
    }

    const changeInputNumber = () => {
      emit('change')
    }

    const getGoodsAddState = (goodsInfo) => {
      return goodsInfo.corpId != loginCorpId.value && (goodsInfo.invoiceMode != invoiceModeSecond || !isJinMaoCompany.value)
    }

    const getC2mIconState = (row) => {
      return C2M_REPO_IDS.includes(row.repositoryId) && row.corpId == JIN_MAO_ID && row.type == 2
    }

    const handleContract = (repositoryId) => {
      emit('contract', repositoryId)
    }

    const contractBtnRef = ref(null)

    return {
      columns,
      isJinMaoCompany,
      loginCorpId,
      handleAddCart,
      handleDetail,
      handleQuality,
      userInfo,
      changeInputNumber,
      judgeIsLogin,
      getGoodsAddState,
      getC2mIconState,
      handleContract,
      contractBtnRef
    }
  }
})
