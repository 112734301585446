import { render, staticRenderFns } from "./goodsDetail.vue?vue&type=template&id=654ac580"
import script from "./goodsDetail.vue?vue&type=script&lang=js"
export * from "./goodsDetail.vue?vue&type=script&lang=js"
import style0 from "./goodsDetail.vue?vue&type=style&index=0&id=654ac580&prod&lang=scss"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {ContentTooltip: require('/usr/src/app/components/basic/contentTooltip.vue').default})
